import request from 'superagent'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

function userFunctions() {
  $("#offcanvasRight").on('show.bs.tab', function (e) {
    if ($(e.target.parentElement.parentElement).hasClass('user') && !dirty) {
      $('#offcanvasRight .offcanvas-body').scrollTop(0)
    }
  })

  $('#offcanvasRight').on('click', '#submitForm', function(event) {
    if ($("#basic-information").find('form').attr('action').includes('users')) {
      lockButton('#submitForm')
      $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
    }
  })

  $('#offcanvasRight').on('click', '#deleteUser', function(event) {
    lockButton('#deleteUser')

    const id = $("#basic-information").find('form').attr('action').split('/').pop()

    request.get('/delete_user_data?id=' + id)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        let select = ''
        if (res.body.users) {
          select = '<br><br>Siirrä tulevat laadut henkilölle <select id="transferEvents" class="form-select">'
          let selected = false
          for(let i in res.body.users) {
            let setSelected = ''
            if (!selected && res.body.ail !== null && res.body.users[i].aux_inc_locations !== null) {
              let ails = res.body.users[i].aux_inc_locations.split(',')
              for(let j in ails) {
                if (ails[j] === res.body.ail) {
                  setSelected = ' selected="selected"'
                  selected = true
                  break
                }
              }
            }
            let name = res.body.users[i].first_name + ' ' + res.body.users[i].last_name
            select += '<option' + setSelected + ' value="' + res.body.users[i].id + '">' + name + '</option>'
          }
          select += '</select>'
        }
        // TODO käännös
        $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Oletko varma?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Tätä toimintoa ei voi peruuttaa.' + select + '</div><div class="modal-footer"><button id="confirmDelete" type="button" class="btn btn-danger" data-id="' + id + '"><i class="bi bi-trash"></i> Poista</button></div></div></div>')
          mainModal.show()

          unlockButton('#deleteUser')
      })
      .catch(err => {
        handleErrors(err, '#deleteUser')
      })
  })

  $('#offcanvasRight').on('change', '#user_role', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() != 'MYYJÄ') {
        $('#offcanvasRight #user_country').prop("disabled", true)
      } else {
        $('#offcanvasRight #user_country').prop("disabled", false)
      }
    }
  })
}

export { userFunctions }
