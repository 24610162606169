import request from 'superagent'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  $('#offcanvasRight').on('click', '.add_fields.add_carpet, .add_fields.add_sanitary_fitting, .add_fields.add_machinery', function(event) {
    dirty = true

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    if ($(this).hasClass('add_carpet')) {
      $(this).closest('.tab-pane').find('.carpet_rows').prepend($(this).data('fields').replace(regexp, time))
      const amount = $('.carpet_rows select[name*="amount"]:first').val()
      const price = parseFloat($('.carpet_rows input[name*="price"]:first').val().replace(',', '.').replace('€', '').trim())
      $('.carpet_rows input[name*="total_price"]:first').val((price * amount).toFixed(2).replace('.', ',') + ' €')
      carpetsTotalPrice()
    } else if ($(this).hasClass('add_sanitary_fitting')) {
      $(this).closest('.tab-pane').find('.sanitary_fitting_rows').prepend($(this).data('fields').replace(regexp, time))
      $('.sanitary_fitting_rows .select_sanitary_product_code:first, .sanitary_fitting_rows .select_description:first').select2({
        minimumInputLength: 0,
        dropdownParent: $('#offcanvasRight'),
        width: '100%'
      })
    } else {
      $(this).closest('.tab-pane').find('.machinery_rows').prepend($(this).data('fields').replace(regexp, time))
      machineryTotalPrice()
    }

    $(this).blur()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '.remove_carpet_row, .remove_machinery_row', function(event) {
    dirty = true

    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('.row').addClass('d-none')
    $(this).closest('.right-details').find('.row').not('.d-none').last().find('.hr').addClass('d-none')

    if ($(this).hasClass('remove_carpet_row')) {
      carpetsTotalPrice()
    } else {
      machineryTotalPrice()
    }

    return event.preventDefault()
  })

  $('#offcanvasRight').on('change', '.select_product_code, .select_size, .select_interval', function(event) {
    lockButton(event.target)
    
    const amount = $(this).closest('.carpet_row').find('select[name*="amount"]').val()
    const interval = $(this).closest('.carpet_row').find('select[name*="interval"]').val()
    if ($(this).hasClass('select_interval')) {
      const val = $(this).closest('.carpet_row').find('select[name*="product_code"]').val()

      request.get('/customers/carpet_options?select=product_code&val=' + val + '&interval=' + interval)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.carpet_row').find('input[name*="price"]').val(parseFloat(res.body.price).toFixed(2).replace('.', ',') + ' €')
          $(this).closest('.carpet_row').find('input[name*="total_price"]').val((parseFloat(res.body.price) * amount).toFixed(2).replace('.', ',') + ' €')
          carpetsTotalPrice()
        
          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    } else {
      const select = $(event.target).attr('class').replace('is-invalid', '').replace('form-select select_', '').trim()
      const val = $(this).val()

      request.get('/customers/carpet_options?select=' + select + '&val=' + val + '&interval=' + interval)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.carpet_row').find('select[name*="product_code"]').val(res.body.product_code)
          $(this).closest('.carpet_row').find('select[name*="size"]').val(res.body.size)
          $(this).closest('.carpet_row').find('input[name*="price"]').val(parseFloat(res.body.price).toFixed(2).replace('.', ',') + ' €')
          $(this).closest('.carpet_row').find('input[name*="total_price"]').val((parseFloat(res.body.price) * amount).toFixed(2).replace('.', ',') + ' €')
          carpetsTotalPrice()
        
          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    }
  })

  $('#offcanvasRight').on('change', '.select_amount', function(event) {
    lockButton(event.target)

    const amount = $(this).val()
    const price = parseFloat($(this).closest('.carpet_row').find('input[name*="price"]').val().replace(',', '.').replace(' €', ''))
    $(this).closest('.carpet_row').find('input[name*="total_price"]').val((price * amount).toFixed(2).replace('.', ',') + ' €')
    carpetsTotalPrice()

    unlockButton(event.target)
  })

  $('#offcanvasRight').on('change', '.contract_extra_carpets_price', function(event) {
    const amount = $(this).closest('.carpet_row').find('select[name*="amount"]').val()
    let price = parseFloat(parseFloat($(this).val().replace(',', '.').replace('€', '').trim()).toFixed(2))
    price = price || 0
    $(this).val(price.toFixed(2).replace('.', ',') + ' €')
    $(this).closest('.carpet_row').find('input[name*="total_price"]').val((price * amount).toFixed(2).replace('.', ',') + ' €')
    carpetsTotalPrice()
  })

  $('#offcanvasRight').on('change', '.contract_extra_machinery_price', function(event) {
    let price = parseFloat(parseFloat($(this).val().replace(',', '.').replace('€', '').trim()).toFixed(2))
    price = price || 0
    $(this).val(price.toFixed(2).replace('.', ',') + ' €')
    machineryTotalPrice()
  })

  $('#offcanvasRight').on('change', '.select_sanitary_product_code, .select_description', function(event) {
    lockButton(event.target)
    
    let select = 'product_code'
    if ($(event.target).hasClass('select_description')) {
      select = 'description'
    }
    const val = $(this).val()

    request.get('/customers/sanitary_fitting_options?select=' + select + '&val=' + val)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        $(this).closest('.sanitary_fitting_row').find('select[name*="product_code"]').val(res.body.product_code).trigger('change.select2')
        $(this).closest('.sanitary_fitting_row').find('select[name*="description"]').val(res.body.description).trigger('change.select2')
        $(this).closest('.sanitary_fitting_row').find('input[name*="unit"]').val(res.body.unit)
        $(this).closest('.sanitary_fitting_row').find('input[name*="price"]').val(parseFloat(res.body.price).toFixed(2).replace('.', ',') + ' €')
      
        unlockButton(event.target)
      })
      .catch(err => {
        handleErrors(err, event.target)
      })
  })

  $('#offcanvasRight').on('change', '.contract_extra_sanitary_fitting_price', function(event) {
    let price = parseFloat(parseFloat($(this).val().replace(',', '.').replace('€', '').trim()).toFixed(2))
    price = price || 0
    $(this).val(price.toFixed(2).replace('.', ',') + ' €')
  })

  $('#offcanvasRight').on('change', '.select_contract_person', function(event) {
    const id = $(this).val()
    if (id) {
      $(this).closest('.contract_extra_contract_person').find('input[name*="contract_person_id"]').val(id)
      lockButton(event.target)

      request.get(/contract_people/ + id)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.contract_person').find('input[name*="first_name"]').val(res.body.first_name)
          $(this).closest('.contract_person').find('input[name*="last_name"]').val(res.body.last_name)
          $(this).closest('.contract_person').find('input[name*="phone"]').val(res.body.phone)
          $(this).closest('.contract_person').find('select[name*="role"]').val('')
          $(this).closest('.contract_person').find('textarea[name*="notes"]').val('')
        
          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    } else {
      $(this).closest('.contract_person').find('input[name*="first_name"]').val('')
      $(this).closest('.contract_person').find('input[name*="last_name"]').val('')
      $(this).closest('.contract_person').find('input[name*="phone"]').val('')
      $(this).closest('.contract_person').find('select[name*="role"]').val('')
      $(this).closest('.contract_person').find('textarea[name*="notes"]').val('')
    }
  })
}

function carpetsTotalPrice() {
  let total = 0
  let showTotal = false
  $('.carpet_row_total_price').each(function(index,data) {
    if (!$(this).closest('.carpet_row').hasClass('d-none')) {
      let price = parseFloat(parseFloat($(this).val().replace(',', '.').replace('€', '').trim()).toFixed(2))
      price = price || 0
      total += price
      showTotal = true
    }
  })
  $('.carpets_total_price').val(total.toFixed(2).replace('.', ',') + ' €')

  if (showTotal) {
    $('.carpets_total_price').closest('.row').removeClass('d-none')
  } else {
    $('.carpets_total_price').closest('.row').addClass('d-none')
  }
}

function machineryTotalPrice() {
  let total = 0
  let showTotal = false
  $('.machinery_row').not('.d-none').find('input[name*="price"]').each(function(index,data) {
    let price = parseFloat(parseFloat($(this).val().replace(',', '.').replace('€', '').trim()).toFixed(2))
    price = price || 0
    total += price
    showTotal = true
  })
  $('.machinery_total_price').val(total.toFixed(2).replace('.', ',') + ' €')

  if (showTotal) {
    $('.machinery_total_price').closest('.row').removeClass('d-none')
  } else {
    $('.machinery_total_price').closest('.row').addClass('d-none')
  }
}
