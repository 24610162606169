import request from 'superagent'
import moment from 'moment-timezone' // https://momentjs.com/
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  $('#offcanvasRight').on('change', '#quality-control-year-select', function(event) {
    if (!dirty) {
      const year = $('#quality-control-year-select').val()
      if (year) {
        lockButton('#quality-control-year-select')

        const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
        request.get('/quality_controls/quality_control_options?customer_id=' + customerId + '&year=' + year)
          .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
          .accept('json')
          .then(res => {
            let options = $('#quality-control-select option').first().prop("outerHTML")
            if (options.toLowerCase().indexOf("selected") === -1) {
              options = options.slice(0, 7) + ' selected="selected"' + options.slice(7)
            }

            for(var i = 0; i < res.body.length; i++) {
              let rating = res.body[i].rating !== null ? res.body[i].rating + ' ' : ''
              options += '<option value="' + res.body[i].id + '">' + rating + moment(res.body[i].check_date).format('D.M.YYYY') + '</option>'
            }
            $('#quality-control-select').empty().append(options)
            
            unlockButton('#quality-control-year-select')
          })
          .catch(err => {
            handleErrors(err, '#quality-control-year-select')
          })
      }
    } else {
      confirmDirtyNav()
    }
  })

  $('#offcanvasRight').on('change', '#quality-control-select', function(event) {
    if (!dirty) {
      const id = $('#quality-control-select').val()
      if (id) {
        lockButton('#quality-control-select')
        $.getScript(/quality_controls/ + id + '/edit')
      }
    } else {
      confirmDirtyNav()
    }
  })

  $('#offcanvasRight').on('click', '.quality_control_add_fields', function(event) {
    dirty = true

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).closest('form').find('.contact_person_rows').append($(this).data('fields').replace(regexp, time))

    $(this).blur()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '.quality-control-remove-record', function(event) {
    dirty = true
    
    $(this).prev('input[type=hidden]').val('1')

    $(this).closest('.row').nextAll('hr:first').hide()
    $(this).closest('.row').hide()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('change', '#quality_control_internal', function(event) {
    if (this.checked) {
      $('.quality-control-remove-record:visible').each(function() {
        $(this).click()
      })
      $('.quality-control-contact-people').addClass('d-none')
      $('.quality-control-cleaning-closet').removeClass('d-none')
    } else {
      $('.quality-control-contact-people').removeClass('d-none')
      $('.quality-control-cleaning-closet').addClass('d-none')
    }
  })

  $('#offcanvasRight').on('change', '.quality_control_select_contact_person', function(event) {
    const id = $(this).val()
    let firstSelectId = null
    if ($(this).hasClass('laatu')) {
      $(this).closest('.contact_person').find('input[name*="contact_person_id"]').val(id)
      firstSelectId = $('.contact_person:visible:first select:first').attr('id')
    } else {
      $(this).closest('.contact_person_quality_control').find('input[name*="contact_person_id"]').val(id)
      firstSelectId = $('.contact_person_quality_control .contact_person:visible:first select:first').attr('id')
    }

    if (id) {
      $(this).closest('.contact_person_quality_control').find('input[name*="contact_person_id"]').val(id)
      lockButton(event.target)

      const customerId = $("#quality_control_customer_id").val()
      request.get(/customers/ + customerId + /contact_people/ + id)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.contact_person').find('input[name*="first_name"]').val(res.body.first_name)
          $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="last_name"]').val(res.body.last_name)
          $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="phone"]').val(res.body.phone)
          $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="email"]').val(res.body.email)
          $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="title"]').val(res.body.title)
          $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", res.body.deleted)

          if (firstSelectId === $(this).attr('id')) {
            $('#quality_control_contact_name').html(res.body.first_name + ' ' + res.body.last_name)
          }
          
          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    } else {
      $(this).closest('.contact_person').find('input[name*="first_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="last_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="phone"]').val('')
      $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="email"]').val('')
      $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="title"]').val('')
      $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", false)

      if (firstSelectId === $(this).attr('id')) {
        $('#quality_control_contact_name').html('')
      }
    }
  })
}
