import request from 'superagent'
import { customerFunctions } from 'packs/customers/functions'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import { addToast } from 'src/toasts'
import { camelize } from 'src/misc'
import { showGoogleMap } from 'src/googleMap'

export default () => {
  customerFunctions()

  // set dropdown values from local storage
  const customersIndexSelectAil = localStorage.getItem("customersIndexSelectAil")
  if (customersIndexSelectAil) {
    $("#select-ail").val(customersIndexSelectAil)
  }
  const customersIndexSelectStatus = localStorage.getItem("customersIndexSelectStatus")
  if (customersIndexSelectStatus) {
    $("#select-status").val(customersIndexSelectStatus)
  }
  
	// datatable init
  let customersDatatable = $('#customers-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": (localStorage.getItem('customersIndexPageLength') || 25),
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#customers-datatable').data('source'),
      "data": function (d) {
        d.ail = $('#select-ail').val()
        d.status = $('#select-status').val()
      }
    },
    "columns": [
      { "data": "company_name" },
      { "data": "location_name" },
      { "data": "business_id" },
      { "data": "income_location" },
      { "data": "aux_inc_location" },
      { "data": "address" },
      { "data": "contact_person" },
      { "data": "phone" },
      { "data": "email" },
      { "data": "status" },
      { "data": "actions", "orderable": false }
    ],
    initComplete(settings, json) {
      $("#datatable-actions").prependTo('#customers-datatable_wrapper')
      $("#customers-datatable_length").prependTo('#datatable-actions')
      $("#customers-datatable_length").addClass('me-3')
      $("#customers-datatable_filter input").removeClass('form-control-sm')
      $("#customers-datatable_filter input").addClass('datatable-search')
      $('#customers-datatable_filter input').attr("type", 'text')
      $("#customers-datatable_filter input").detach().prependTo('#search-div')
      $('#customers-datatable_wrapper .row:first').remove()
      $("#datatable-actions").removeClass('d-none')

      $('.datatable-search').on('input', function() {
        if ($('#search-div input').val()) {
          $('.clear-button').removeClass('d-none')
        } else {
          $('.clear-button').addClass('d-none')
        }
      })
  	}
  })

  $('#content').on('change', '#select-ail, #select-status', function() {
    localStorage.setItem(camelize('customers-index-' + $(this).attr('id')), $(this).val())
    customersDatatable.ajax.reload()
  })

  $('#content').on('change', "[name='customers-datatable_length']", function() {
    localStorage.setItem('customersIndexPageLength', $(this).val())
  })

  $('.clear-button').on('click', function() {
    $('.clear-button').addClass('d-none')
    customersDatatable.search('').draw()
  })

  $('#content').on('click', '#add_customer', function() {
    lockButton('#add_customer')
    offcanvasRight.show()
    $.getScript('/customers/new')
  })

  $('#content').on('click', '#customers, #contact_people', function(event) {
    lockButton(event.target)
    let status = $('#select-status').val()
    let query = $('#search-div input').val()
    let url = $(event.target).attr('id')
    let q = '/customers/' + url + '_list?status=' + status + '&query=' + query
    
    if ($('#select-ail').length) {
      q += '&ail=' + $('#select-ail').val()
    }

    let dynamicLink = $('<a>', {
      href: q,
      target: '_blank',
      css: { display: 'none' }
    })

    $('body').append(dynamicLink)
    dynamicLink[0].click()
    dynamicLink.remove()

    unlockButton(event.target)
  })

  $('#content').on('click', '#map', function(event) {
    lockButton(event.target)

    const status = $('#select-status').val()
    const query = $('#search-div input').val()
    let url = '/customers/address_list?status=' + status + '&query=' + query
    
    if ($('#select-ail').length) {
      url += '&ail=' + $('#select-ail').val()
    }

    request.get(url)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        let addresses = []
        $.each(res.body.addresses, function(index, value) {
          const totalAddress = (value.street_name + ', ' + value.post_code + ' ' + value.town).trim()
          let title = value.company_name
          if (value.location_name) {
            title += ', ' + value.location_name
          }
          if (totalAddress.length > 1) {
            addresses.push({ address: totalAddress + ', ' + value.country, title: title + "\n" + totalAddress })
          }
        })

        unlockButton(event.target)
        if (addresses.length) {
          showGoogleMap(mainModal, '', addresses)
        } else {
          // TODO käännös
          addToast('Ei osoitteita', 'alert')
        }
      })
      .catch(err => {
        handleErrors(err, event.target)
      })
  })

  $('#content').on('click', '.show-googlemap', function(event) {
    $(event.target).prop("disabled", true)

    const companyName = $(this).data('company_name')
    const locationName = $(this).data('location_name')
    const streetName = $(this).data('street_name')
    const postCode = $(this).data('post_code')
    const town = $(this).data('town')
    const country = $(this).data('country')
    const totalAddress = (streetName + ", " + postCode + " " + town).trim()
    if (totalAddress.length > 1) {
      let title = companyName
      if (locationName) {
        title += ', ' + locationName
      }
      showGoogleMap(mainModal, title, [{ address: totalAddress + ', ' + country, title: totalAddress }])
    } else {
      // TODO käännös
      addToast('Ei osoitetta', 'alert')
    }

    $(event.target).prop("disabled", false)
  })
}
