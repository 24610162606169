import { addToast } from 'src/toasts'

function initGoogleMap(locations) {
  const mapElement = document.getElementById("googleMap");
  if (!mapElement) {
    return; // Prevent errors if the map element does not exist
  }

  let geocoder = new google.maps.Geocoder()
  googleMap = new google.maps.Map(document.getElementById("googleMap"), {
    mapId: "DEMO_MAP_ID",
    zoom: 12/*,
    center: {
      lat: coords.getAttribute("data-latitude"),
      lng: coords.getAttribute("data-longitude")
    }*/
  });

  let error = false
  for (var i = 0; i < Math.min(locations.length, 100); i++) {
    if (!error) {
      const title = locations[i].title
      geocoder.geocode({ address: locations[i].address }, (results, status) => {
        if (status === "OK") {
          const marker = new google.maps.marker.AdvancedMarkerElement({
            map: googleMap,
            position: results[0].geometry.location,
            title: title
          });
          googleMap.setCenter(results[0].geometry.location);
        } else {
          // TODO käännös
          addToast('Virhe hakiessa osoitteen tietoja', 'error')
          error = true
        }
      });
    } else {
      break;
    }
  }

  $("#mainModal .loader").addClass('d-none')
  $("#mainModal #googleMap").removeClass('d-none')
}

export function showGoogleMap(mainModal, title, locations) {
  // TODO käännös
  let titleCode = ''
  if (title.length) {
    titleCode = '<div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">' + title + '</h4></div>'
  }

  $("#mainModal").html('<div class="modal-dialog"><div class="modal-content">' + titleCode + '<div class="modal-body text-center"><div id="googleMap" class="d-none"></div><div class="loader">Loading...</div></div><div class="modal-footer"><button id="modal-cancel" type="button" class="btn btn-secondary"><i class="bi bi-close"></i> Sulje</button></div></div></div>')
  mainModal.show()

  setTimeout(() => {
    initGoogleMap(locations); // Initialize map when modal is fully shown
  }, 300); // Delay slightly to ensure rendering
}

$.getScript('https://maps.googleapis.com/maps/api/js?key=' + process.env.GOOGLE_MAPS_API_KEY + '&loading=async&libraries=places,marker')
