import request from 'superagent'
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import { camelize } from 'src/misc'

export default () => {
  // set dropdown values from local storage
  const usersIndexSelectRole = localStorage.getItem("usersIndexSelectRole")
  if (usersIndexSelectRole) {
    $("#select-role").val(usersIndexSelectRole)
  }
  const usersIndexSelectAil = localStorage.getItem("usersIndexSelectAil")
  if (usersIndexSelectAil) {
    $("#select-ail").val(usersIndexSelectAil)
  }

	// datatable init
  let usersDatatable = $('#users-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": (localStorage.getItem('usersIndexPageLength') || 25),
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#users-datatable').data('source'),
      "data": function (d) {
        d.role = $('#select-role').val()
        d.ail = $('#select-ail').val()
      }
    },
    "columns": [
      { "data": "first_name" },
      { "data": "last_name" },
      { "data": "phone" },
      { "data": "email" },
      { "data": "role" },
      { "data": "title" },
      { "data": "company" },
      { "data": "aux_inc_locations" },
      { "data": "sign_in_count" },
      { "data": "current_sign_in_at" },
      { "data": "actions", "orderable": false }
    ],
    initComplete(settings, json) {
      $("#datatable-actions").prependTo('#users-datatable_wrapper')
      $("#users-datatable_length").prependTo('#datatable-actions')
      $("#users-datatable_length").addClass('me-3')
      $("#users-datatable_filter input").removeClass('form-control-sm')
      $("#users-datatable_filter input").addClass('datatable-search')
      $('#users-datatable_filter input').attr("type", 'text')
      $("#users-datatable_filter input").detach().prependTo('#search-div')
      $('#users-datatable_wrapper .row:first').remove()
      $("#datatable-actions").removeClass('d-none')

      $('.datatable-search').on('input', function() {
        if ($('#search-div input').val()) {
          $('.clear-button').removeClass('d-none')
        } else {
          $('.clear-button').addClass('d-none')
        }
      })
  	}
  })

  $('#content').on('change', "[name='users-datatable_length']", function() {
    localStorage.setItem('usersIndexPageLength', $(this).val())
  })

  $('#content').on('change', '#select-role, #select-ail', function() {
    localStorage.setItem(camelize('users-index-' + $(this).attr('id')), $(this).val())
    usersDatatable.ajax.reload()
  })

  $('.clear-button').on('click', function() {
    $('.clear-button').addClass('d-none')
    usersDatatable.search('').draw()
  })

  $('#content').on('click', '#add_user', function() {
    lockButton('#add_user')
    offcanvasRight.show()
    $.getScript('/users/new')
  })

  $('#mainModal').on('click', '#confirmDelete', function(event) {
    $(this).blur()

    if ($("body.users.index").length) {
      lockButton('#confirmDelete')
      let params = $('#confirmDelete').data('id')
      if ($('#transferEvents').length) {
        params += '?new_id=' + $('#transferEvents').val()
      }
      
      request.delete(/users/ + params)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          addToast(res.body.success, 'notice')
          usersDatatable.ajax.reload()
          mainModal.hide()
          $('#offcanvasRight .btn-close').click()
        })
        .catch(err => {
          handleErrors(err, '#confirmDelete')
        })
    }
  })

  $('#content').on('click', '.delete-user', function(event) {
    lockButton(event.target)

    const id = $(this).data('id')

    request.get('/delete_user_data?id=' + id)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        let select = ''
        if (res.body.users) {
          select = '<br><br>Siirrä tulevat laadut henkilölle <select id="transferEvents" class="form-select">'
          let selected = false
          for(let i in res.body.users) {
            let setSelected = ''
            if (!selected && res.body.ail !== null && res.body.users[i].aux_inc_locations !== null) {
              let ails = res.body.users[i].aux_inc_locations.split(',')
              for(let j in ails) {
                if (ails[j] === res.body.ail) {
                  setSelected = ' selected="selected"'
                  selected = true
                  break
                }
              }
            }
            let name = res.body.users[i].first_name + ' ' + res.body.users[i].last_name
            select += '<option' + setSelected + ' value="' + res.body.users[i].id + '">' + name + '</option>'
          }
          select += '</select>'
        }
        // TODO käännös
        $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Oletko varma?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Tätä toimintoa ei voi peruuttaa.' + select + '</div><div class="modal-footer"><button id="confirmDelete" type="button" class="btn btn-danger" data-id="' + id + '"><i class="bi bi-trash"></i> Poista</button></div></div></div>')
          mainModal.show()

          unlockButton(event.target)
      })
      .catch(err => {
        handleErrors(err, event.target)
      })
  })

  $('#content').on('click', '.move-user-data', function(event) {
    lockButton(event.target)

    const id = $(this).data('id')

    request.get('/search_users?role=ADMIN,PP,PEM,PO')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        let select = '<div class="form-group form-floating"><select id="move-user-data-user-select" class="form-select">'
        const a = res.body
        for(let i in a) {
          if (a[i].id !== id) {
            select += '<option value="' + a[i].id + '">' + a[i].fullName + '</option>'
          }
        }
        select += '</select><label for="move-user-data-user-select">Käyttäjä</label></div>'

        // TODO käännös
        let checkboxes = '<div class="form-group"><input class="form-check-input" type="checkbox" value="1" id="move-user-data-customers"><label for="move-user-data-customers" class="ms-1">Asiakkaat</label></div>'
        checkboxes += '<div class="form-group"><input class="form-check-input" type="checkbox" value="1" id="move-user-data-quality-controls"><label for="move-user-data-quality-controls" class="ms-1">Tulevat laadut</label></div>'

        // TODO käännös
        $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Siirrä toiselle käyttäjälle</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">' + select + checkboxes + '</div><div class="modal-footer"><button id="confirmMoveUserData" type="button" class="btn btn-success" data-id="' + id + '"><i class="bi bi-check-lg"></i> Siirrä</button></div></div></div>')
          mainModal.show()

          unlockButton(event.target)
      })
      .catch(err => {
        handleErrors(err, event.target)
      })
  })

  $('#mainModal').on('click', '#confirmMoveUserData', function(event) {
    const customer = $('#move-user-data-customers').is(':checked')
    const qualityControls = $('#move-user-data-quality-controls').is(':checked')

    if (customer || qualityControls) {
      lockButton('#confirmMoveUserData')

      let params = '?user_id=' + $(this).data('id')
      params += '&new_user_id=' + $('#move-user-data-user-select').val()
      if (customer) {
        params += '&customers=true'
      }
      if (qualityControls) {
        params += '&quality_controls=true'
      }
      
      request.post('/move_user_data' + params)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          addToast(res.body.success, 'notice')
          mainModal.hide()
        })
        .catch(err => {
          handleErrors(err, '#confirmMoveUserData')
        })
    } else {
      // TODO käännös
      addToast('Valitse ainakin yksi siirrettävä tieto', 'alert')
    }
  })
}
