// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//require("@popperjs/core")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap")








require("jquery-ui")
require("/node_modules/jquery-ui/ui/i18n/datepicker-fi.js")
import '/node_modules/jquery-ui/themes/base/all.css'

require("/node_modules/timepicker/jquery.timepicker.min.js")
import '/node_modules/timepicker/jquery.timepicker.min.css'

// Load Datatables
require('datatables.net-bs5')
require('datatables.net-buttons-bs5')
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css'

import { Offcanvas, Modal } from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css' // https://icons.getbootstrap.com/

import request from 'superagent' // https://visionmedia.github.io/superagent/
//import select2 from 'select2'
require('select2') // https://select2.org/
require("/node_modules/select2/dist/js/i18n/fi.js")
//import '/node_modules/select2/dist/css/select2.min.css'
import "/node_modules/select2/src/scss/core.scss"
import "/node_modules/select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css"

import 'css/site'
import { showToasts, addToast } from 'src/toasts'
import { lockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

import contract_people_index from './contract_people/index'
import customers_index from './customers/index'
import events_call_list from './events/call_list'
import dashboard_admin from './dashboard/admin'
import dashboard_buukkaaja from './dashboard/buukkaaja'
import dashboard_myyja from './dashboard/myyja'
import dashboard_pem from './dashboard/pem'
import dashboard_po from './dashboard/po'
import dashboard_pp from './dashboard/pp'
import users_index from './users/index'
import { userFunctions } from 'packs/users/functions'
import quality_controls_index from './quality_controls/index'
import quality_controls_laatu from './quality_controls/laatu'

$(document).ajaxError(function(event, jqxhr, settings, thrownError) {
  if (jqxhr.status === 401) {
    if (Object.keys(jqxhr.responseJSON).length > 0) {
      // show ajax errors
      Object.keys(jqxhr.responseJSON).forEach(function(key) {
        addToast(jqxhr.responseJSON[key], key)
      })
      // redirect to root later
      setTimeout(function() {
        $.getScript('/redirect_to_root')
      }, 3000)
    } else {
      // no errors to show, redirect immediately
      $.getScript('/redirect_to_root')
    }
  }
})

let sessionTimer
let sessionTimer2
window.addEventListener('beforeunload', function onBeforeUnload(e) {
  if (dirty) {
    e.preventDefault();
    e.returnValue = '';
    return;
  }

  delete e['returnValue'];
})
window.fullscreenLoader = null
window.mainModal = null
window.navTarget = null
window.offcanvasRight = null
window.dirty = false
window.userRole = 'BUUKKAAJA'
window.mainCustomer = false
window.googleMap = null
window.confirmDirtyNav = function() {
  // TODO käännös
  $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Oletko varma?</h4></div><div class="modal-body text-center">Sinulla on tallentamattomia muutoksia. <b>Tallenna lomake jatkaaksesi.</b></div><div class="modal-footer"><button id="modal-cancel" type="button" class="btn btn-secondary"><i class="bi bi-close"></i> Peruuta</button></div></div></div>')
  mainModal.show()
}

document.addEventListener("turbolinks:load", function() {
  // only when signed in
  if ($("#content").length || $(".quality_controls.laatu").length) {
    setTimeoutTimer(1000)

    $('#mainModal').on('click', '#modal_sign_out', function(event) {
      lockButton('#modal_sign_out')
      signOut('#modal_sign_out')
    })

    $('#mainModal').on('click', '#modal_continue_session', function(event) {
      lockButton('#modal_continue_session')

      request.put('/sessions/reset_user_clock')
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('text/javascript')
        .then(res => {
          mainModal.hide()
          setTimeoutTimer(1000)
        })
        .catch(err => {
          handleErrors(err, '#modal_continue_session')
        })
    })

    // https://api.jqueryui.com/datepicker/
    $.datepicker.setDefaults( $.datepicker.regional[ "fi" ] )
    $.datepicker.setDefaults({
      dateFormat: 'd.m.yy',
      firstDay: 1,
      showWeek : true
    })

    $.fn.dataTable.ext.errMode = 'none';

    // https://select2.org/
    $.fn.select2.defaults.set('language', 'fi')
    $.fn.select2.defaults.set('minimumInputLength', 3)
    $.fn.select2.defaults.set('selectionCssClass', ':all:')
    $.fn.select2.defaults.set('theme', 'bootstrap-5')
    $(document).on("select2:open", () => {
      document.querySelector(".select2-container--open .select2-search__field").focus()
    })

    const mainModalElement = document.getElementById('mainModal')
    mainModal = new Modal(mainModalElement, {
      backdrop: 'static',
      keyboard: false,
      focus: true
    })

    mainModalElement.addEventListener('hidden.bs.modal', function (event) {
      if (document.getElementById("googleMap")) {
        document.getElementById("googleMap").innerHTML = ""; // Remove map to prevent multiple instances
        googleMap = null;
      }

      fullscreenLoader.hide()
      $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold"></h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body"></div></div></div>')
    })

    $('#mainModal').on('click', '#modal-cancel', function() {
      mainModal.hide()
    })

    $('#mainModal').on('click', '#modal-continue-nav', function(event) {
      mainModal.hide()
      dirty = false
      if (navTarget === 'offcanvasRight') {
        offcanvasRight.hide()
      } else {
        $('#' + navTarget).find('button').click()
      }
    })

    const fullscreenLoaderElement = document.getElementById('fullscreenLoader')
    fullscreenLoader = new Modal(fullscreenLoaderElement, {
      backdrop: false,
      keyboard: false,
      focus: true
    })

    if ($(".quality_controls.laatu").length) {
      $('header').on('click', '#sign-out', function(event) {
        lockButton('#sign-out')
        signOut('#sign-out')
      })

      quality_controls_laatu()
    } else {
      request.get(/current_user_data/)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          userRole = res.body.role
        })
        .catch(err => {
          handleErrors(err, null)
        })

      $('#user-info').on('click', '#sign-out', function(event) {
        lockButton('#sign-out')
        // TODO käännös
        /*$("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Kirjaudu ulos</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Kirjaudutaanko ulos myös Microsoft-tililtä?</div><div class="modal-footer"><a href="/users/sign_out?ms=false" data-method="delete" class="btn btn-primary"><i class="bi bi-x-lg"></i> Ei</a><a href="/users/sign_out?ms=true" data-method="delete" class="btn btn-danger"><i class="bi bi-check-lg"></i> Kyllä</a></div></div></div>')
        mainModal.show()*/

        signOut('#sign-out')
      })

      let offcanvasRightElement = document.getElementById('offcanvasRight')
      if (offcanvasRightElement) {
        offcanvasRight = new Offcanvas(offcanvasRightElement)

        offcanvasRightElement.addEventListener('show.bs.offcanvas', function(e) {
          fullscreenLoader.show()
        })

        offcanvasRightElement.addEventListener('hidden.bs.offcanvas', function(e) {
          $("#offcanvasRight .offcanvas-body").html('')
          $("#offcanvasRightLabel2").html('')
          $("#offcanvasRightLabel3").html('')
          $("#offcanvasRightLabel4").html('')
          $("#offcanvasRightButtons").html('')
          $("#offcanvasRightHeaderNav").html('')
          
          dirty = false
          navTarget = null

          mainModal.hide()
        })

        $('#offcanvasRightButtons').on('click', '#delete', function(event) {
          $(this).blur()
          // TODO käännös
          $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Oletko varma?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Tätä toimintoa ei voi peruuttaa.</div><div class="modal-footer"><button id="confirmDelete" type="button" class="btn btn-danger"><i class="bi bi-trash"></i> Poista</button></div></div></div>')
            mainModal.show()
        })

        $('#offcanvasRight').on('change', 'form input, form select, form textarea, form checkbox, form radio', function(event) {
          dirty = true
        })

        $("#offcanvasRight").on('hide.bs.offcanvas', function (e) {
          navTarget = 'offcanvasRight'

          if (dirty) {
            confirmDirtyNav()

            return e.preventDefault()
          }
        })

        $("#offcanvasRight").on('show.bs.tab', function (e) {
          navTarget = e.target.parentElement.id

          if (dirty) {
            confirmDirtyNav()

            return e.preventDefault()
          }
        })

        userFunctions()
      }

      $('#sidebar').on('click', '.sidebar-header', function(event) {
        $(this).blur()
        $(this).removeClass('new_system_updates')
        $.getScript('/system_updates')
        mainModal.show()
      })

      // page specific code
      if (document.querySelectorAll(".contract_people.index").length) {
        contract_people_index()
      } else if (document.querySelectorAll(".customers.index").length) {
        customers_index()
      } else if (document.querySelectorAll(".dashboard-admin").length) {
        dashboard_admin()
      } else if (document.querySelectorAll(".dashboard-buukkaaja").length) {
        dashboard_buukkaaja()
      } else if (document.querySelectorAll(".dashboard-myyja").length) {
        dashboard_myyja()
      } else if (document.querySelectorAll(".dashboard-pem").length) {
        dashboard_pem()
      } else if (document.querySelectorAll(".dashboard-po").length) {
        dashboard_po()
      } else if (document.querySelectorAll(".dashboard-pp").length) {
        dashboard_pp()
      } else if (document.querySelectorAll(".events.call_list").length) {
        events_call_list()
      } else if (document.querySelectorAll(".quality_controls.index").length) {
        quality_controls_index()
      } else if (document.querySelectorAll(".users.index").length) {
        users_index()
      }
    }
  }

  // show toasts
  showToasts()
  var mutationObserver = new MutationObserver(function(mutations) {
    showToasts()
  });
  mutationObserver.observe(document.getElementById('toastContainer'), {
    childList: true,
    subtree: true
  });
})

function setTimeoutTimer(time_to_wait) {
 sessionTimer = setTimeout(function() {
    clearTimeout(sessionTimer)
    
    request.get('/sessions/check_time_until_logout')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        const sessionTimeLeft = res.body.time_left - 60

        if (sessionTimeLeft <= 0) {
          mainModal.hide()

          // TODO käännös
          $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Istunto vanhenee</h4></div><div class="modal-body text-center"><p class="m-0">Istuntosi vanhenee</p><h1 id="session_timer" class="p-0 m-2 font-weight-bold">' + res.body.time_left + '</h1><p class="m-0">sekunnin kuluttua.</p></div><div class="modal-footer"><button id="modal_sign_out" type="button" class="btn btn-danger"><i class="bi bi-box-arrow-right"></i> Kirjaudu ulos</button><button id="modal_continue_session" type="button" class="btn btn-success""><i class="bi bi-check"></i> Jatka</button></div></div></div>')
          mainModal.show()

          clearTimeout(sessionTimer2)
          setTimeout(setTimeoutTimer2(res.body.time_left), 1000)
        } else {
          setTimeoutTimer(sessionTimeLeft * 1000)
        }
      })
      .catch(err => {
        handleErrors(err, null)
      })
  }, time_to_wait)
}

function setTimeoutTimer2(time_to_wait) {
  sessionTimer2 = setTimeout(function() {
    if (time_to_wait-- > 0) {
      $('#session_timer').html(time_to_wait)
      clearTimeout(sessionTimer2)
      setTimeoutTimer2(time_to_wait)
    } else {
      signOut(null)
    }
  }, 1000)
}

function signOut(button) {
  // TODO korjaa myös users serssion controller kun ms otetaan käyttöön
  request.delete('/users/sign_out?ms=false')
    .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    .accept('json')
    .then(res => {
      window.location.href = '/'
    })
    .catch(err => {
      handleErrors(err, button)
    })
}
