import request from 'superagent'
import { customerFunctions } from 'packs/customers/functions'
import moment from 'moment' // https://momentjs.com/
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import { Tooltip } from 'bootstrap'
import { camelize } from 'src/misc'

window.initTooltips = function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
}

window.eventDragDrop = function() {
  $('.quality-schedule-event.draggable').show(function(event) {
    $(this).draggable({
      revert: function(is_valid_drop) {
        return !is_valid_drop
      }
    })
  })

  $('.quality-schedule-month').show(function(event) {
    $(this).droppable({
      activeClass: 'active',
      hoverClass: 'hover',
      accept: function(elem) {
        const targetCustomer = $(this)[0].attributes.getNamedItem("data-customer").value
        const targetMonth = parseInt($(this)[0].attributes.getNamedItem("data-month").value)
        const customerId = elem[0].attributes.getNamedItem("data-customer").value
        const checkDate = moment(elem[0].attributes.getNamedItem("data-check-date").value)

        return targetCustomer === customerId && getQuarter(targetMonth) === getQuarter(checkDate.month() + 1) && targetMonth !== checkDate.month() + 1
      },
      drop: function (e, ui) {
        fullscreenLoader.show()

        const eventId = ui.draggable[0].attributes.getNamedItem("data-event").value
        const targetMonth = parseInt($(this)[0].attributes.getNamedItem("data-month").value)
        const checkDate = moment(ui.draggable[0].attributes.getNamedItem("data-check-date").value)
        let newDate = moment(ui.draggable[0].attributes.getNamedItem("data-check-date").value)

        newDate.set('month', targetMonth - 1)
        if (targetMonth === 12) {
          newDate.set('year', checkDate.year() - 1)
        } else if (checkDate.month() === 11) {
          newDate.set('year', checkDate.year() + 1)
        }

        request.patch('/quality_controls/' + eventId + '/update_date')
          .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
          .accept('json')
          .send({
            check_date: newDate.format('YYYY-MM-DD')
          })
          .then(res => {
            addToast(res.body.success, 'notice')

            $.getScript(getScheduleQuery())

            fullscreenLoader.hide()
          })
          .catch(err => {
            handleErrors(err, null)
            fullscreenLoader.hide()
          })
      }
    })
  })
}

export default () => {
  customerFunctions()
  let timeoutHandle = null

  // set dropdown values from local storage
  const qualityControlsIndexSelectEmpty = localStorage.getItem("qualityControlsIndexSelectEmpty")
  if (qualityControlsIndexSelectEmpty) {
    $("#select-empty").val(qualityControlsIndexSelectEmpty)
  }
  const qualityControlsIndexSelectType = localStorage.getItem("qualityControlsIndexSelectType")
  if (qualityControlsIndexSelectType) {
    $("#select-type").val(qualityControlsIndexSelectType)
    if (qualityControlsIndexSelectType !== 'All') {
      $('#select-empty').closest('div').addClass('d-none')
    }
  }
  const qualityControlsIndexSelectYear = localStorage.getItem("qualityControlsIndexSelectYear")
  const qualityControlsIndexPreviousMonth = localStorage.getItem("qualityControlsIndexPreviousMonth")
  const qualityControlsIndexNextMonth = localStorage.getItem("qualityControlsIndexNextMonth")
  if (qualityControlsIndexSelectYear && qualityControlsIndexPreviousMonth && qualityControlsIndexNextMonth) {
    $("#select-year").val(qualityControlsIndexSelectYear)
    $('#previous-month').attr("data-date", qualityControlsIndexPreviousMonth)
    $('#next-month').attr("data-date", qualityControlsIndexNextMonth)
  }
  const qualityControlsIndexSelectAil = localStorage.getItem("qualityControlsIndexSelectAil")
  if (qualityControlsIndexSelectAil && qualityControlsIndexSelectAil !== '-1') {
    $("#select-ail").val(qualityControlsIndexSelectAil)
  }
  fullscreenLoader.show()
  $.getScript(getScheduleQuery())

  $('#offcanvasRight').on('click', '[data-bs-dismiss="offcanvas"]', function(event) {
    lockButton(event.target)
    $.getScript(getScheduleQuery())
  })

  $('#select-type, #select-ail, #select-empty').change(function(event) {
    lockButton(event.target)
    localStorage.setItem(camelize('quality-controls-index-' + $(this).attr('id')), $(this).val())
    if ($('#select-type').val() === 'All') {
      $('#select-empty').closest('div').removeClass('d-none')
    } else {
      $('#select-empty').closest('div').addClass('d-none')
    }
    $.getScript(getScheduleQuery())
  })

  $('#select-year').change(function() {
    const year = $('#select-year').val()
    localStorage.setItem('qualityControlsIndexSelectYear', year)

    if (year) {
      lockButton(event.target)
      $('#previous-month').attr("data-date", year + '-05-01')
      $('#next-month').attr("data-date", year + '-07-01')
      localStorage.setItem('qualityControlsIndexPreviousMonth', year + '-05-01')
      localStorage.setItem('qualityControlsIndexNextMonth', year + '-07-01')

      let query = getScheduleQuery()
      if (query.split('/quality_controls/query_data')[1].length > 0) {
        query += '&year=' + year
      } else {
        query += '?year=' + year
      }

      $.getScript(query)
    }
  })

  $('ul.pagination li').on('click', function(event) {
    lockButton(event.target)

    $('#select-year').val('').change()

    const date = $(this)[0].attributes.getNamedItem("data-date").value
    const prev = moment(date).subtract(1, 'months').format('YYYY-MM-DD')
    const next = moment(date).add(1, 'months').format('YYYY-MM-DD')
    $('#previous-month').attr("data-date", prev)
    $('#next-month').attr("data-date", next)
    localStorage.setItem('qualityControlsIndexPreviousMonth', prev)
    localStorage.setItem('qualityControlsIndexNextMonth', next)

    $.getScript(getScheduleQuery() + '&date=' + date)
  })

  $('#search-name').on('input', function(event) {
    if ($('#search-name').val()) {
      $('.clear-button').removeClass('d-none')
    } else {
      $('.clear-button').addClass('d-none')
    }

    window.clearTimeout(timeoutHandle)
    timeoutHandle = window.setTimeout(function() {
      $.getScript(getScheduleQuery())
    }, 400)
  })

  $('.clear-button').on('click', function(event) {
    lockButton(event.target)
    $('#search-name').val('')
    $('.clear-button').addClass('d-none')
    $.getScript(getScheduleQuery())
  })

  $('#quality-schedule-table').on('click', '.quality-schedule-add-event', function(event) {
    lockButton(event.target)

    const customer = $(this)[0].attributes.getNamedItem("data-customer").value
    const date = $(this)[0].attributes.getNamedItem("data-date").value
    
    request.post('/quality_controls')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .send({
        customer_id: customer,
        check_date: date
      })
      .then(res => {
        addToast(res.body.success, 'notice')
        $.getScript(getScheduleQuery())

        unlockButton(event.target)
      })
      .catch(err => {
        handleErrors(err, event.target)
      })
  })

  $('#quality-schedule-table').on('click', '.quality-schedule-event', function(event) {
    lockButton(event.target)
    
    const customer = $(this)[0].attributes.getNamedItem("data-customer").value
    const eventId = $(this)[0].attributes.getNamedItem("data-event").value
    
    $.getScript('/customers/' + customer + '/edit?qc_id=' + eventId)
    offcanvasRight.show()
  })

  eventDragDrop()
  initTooltips()

  $('#customers, #contact_people').on('click', function(event) {
    lockButton(event.target)
    let aux_inc_location = ''
    if ($('#select-ail').length) {
      aux_inc_location = $('#select-ail').val()
    } else {
      aux_inc_location = '-1'
    }

    let action = 'customers_list'
    if (this.id == 'contact_people') {
      action = 'contact_people_list'
    }

    let query = '/customers/' + action + '?ail=' + aux_inc_location + '&status=ASIAKAS'

    const searchName = $('#search-name').val()
    if (searchName) {
      query += '&query=' + searchName
    }

    let dynamicLink = $('<a>', {
      href: query,
      target: '_blank',
      css: { display: 'none' }
    })

    $('body').append(dynamicLink)
    dynamicLink[0].click()
    dynamicLink.remove()

    unlockButton(event.target)
  })

  $('#deviations').on('click', function(event) {
    $.getScript('/quality_controls/deviations')
  })

  $('#download').on('click', function(event) {
    lockButton(event.target)
    let query = getScheduleQuery()
    if (query.split('/quality_controls/query_data')[1].length > 0) {
      query += '&download=true'
    } else {
      query += '?download=true'
    }

    let dynamicLink = $('<a>', {
      href: query,
      target: '_blank',
      css: { display: 'none' }
    })

    $('body').append(dynamicLink)
    dynamicLink[0].click()
    dynamicLink.remove()

    unlockButton(event.target)
  })

  $('#mainModal').on('change', '#deviations-select-ail', function(event) {
    if (($(this).val().includes('-1') && $(this).data('val').toString() !== ['-1'].toString()) || $(this).val().length === 0) {
      $(this).data('val', '-1')
      $(this).val('-1').trigger('change')
    } else if ($(this).data('val').includes('-1') && $(this).val().length > 1) {
      let newVal = $(this).val()
      newVal.shift()
      $(this).data('val', newVal)
      $(this).val(newVal).trigger('change')
    }
  })


  $('#mainModal').on('click', '#downloadDeviations', function(event) {
    if ($('#deviations-select-quarter').val().length === 0) {
      $('#deviations-select-quarter').addClass('is-invalid')
      $('#deviations-select-quarter').parent().find('.select2-selection').addClass('is-invalid')
      // TODO käännös
      $('#deviations-select-quarter').parent().find('.select2').after('<div class="errors">Ei voi olla sisällötön</div>')
    } else {
      lockButton(event.target)
      let aux_inc_location = $('#deviations-select-ail').val()
      let quarter = $('#deviations-select-quarter').val()

      let dynamicLink = $('<a>', {
        href: '/quality_controls/download_deviations?aux_inc_location=' + aux_inc_location + '&quarter=' + quarter,
        target: '_blank',
        css: { display: 'none' }
      })

      $('body').append(dynamicLink)
      dynamicLink[0].click()
      dynamicLink.remove()
      mainModal.hide()

      unlockButton(event.target)
    }
  })
}

function getQuarter(month) {
  if (month >= 6 && month <= 8) {
    return 1
  } else if (month >= 9 && month <= 11) {
    return 2
  } else if (month === 12 || month <= 2) {
    return 3
  } else {
    return 4
  }
}

function getScheduleQuery() {
  let query = ''

  const type = $('#select-type').val()
  if (type) {
    query += '?type=' + type

    if ($('#select-empty').is(":visible")) {
      query += '&empty=' + $('#select-empty').val()
    }
  }

  const ail = $('#select-ail').val()
  if (ail) {
    if (query.length > 0) {
      query += '&'
    } else {
      query += '?'
    }
    query += 'ail=' + ail
  }

  if ($('#previous-month').length) {
    if (query.length > 0) {
      query += '&'
    } else {
      query += '?'
    }
    const date = moment($('#previous-month').attr("data-date")).add(1, 'months').format('YYYY-MM-DD')
    query += 'date=' + date
  }

  const searchName = $('#search-name').val()
  if (searchName) {
    if (query.length > 0) {
      query += '&'
    } else {
      query += '?'
    }
    query += 'search_name=' + searchName
  }

  return '/quality_controls/query_data' + query
}
