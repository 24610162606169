import request from 'superagent'
import { addToast } from 'src/toasts'
import { lockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import { contractPeopleFunctions } from 'packs/contract_people/functions'

export default () => {
  contractPeopleFunctions(contractPeopleDatatable)

	// datatable init
  let contractPeopleDatatable = $('#contract-people-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": (localStorage.getItem('contractPeopleIndexPageLength') || 25),
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#contract-people-datatable').data('source')
    },
    "columns": [
      { "data": "first_name" },
      { "data": "last_name" },
      { "data": "phone" },
      { "data": "customers" }
    ],
    initComplete(settings, json) {
      $("#datatable-actions").prependTo('#contract-people-datatable_wrapper')
      $("#contract-people-datatable_length").prependTo('#datatable-actions')
      $("#contract-people-datatable_length").addClass('me-3')
      $("#contract-people-datatable_filter input").removeClass('form-control-sm')
      $("#contract-people-datatable_filter input").addClass('datatable-search')
      $('#contract-people-datatable_filter input').attr("type", 'text')
      $("#contract-people-datatable_filter input").detach().prependTo('#search-div')
      $('#contract-people-datatable_wrapper .row:first').remove()
      $("#datatable-actions").removeClass('d-none')

      $('.datatable-search').on('input', function() {
        if ($('#search-div input').val()) {
          $('.clear-button').removeClass('d-none')
        } else {
          $('.clear-button').addClass('d-none')
        }
      })
  	}
  })

  $('#content').on('change', "[name='contract-people-datatable_length']", function() {
    localStorage.setItem('contractPeopleIndexPageLength', $(this).val())
  })

  $('.clear-button').on('click', function() {
    $('.clear-button').addClass('d-none')
    contractPeopleDatatable.search('').draw()
  })

  $('#content').on('click', '#add_contract_person', function() {
    lockButton('#add_contract_person')
    offcanvasRight.show()
    $.getScript('/contract_people/new')
  })

  $('#mainModal').on('click', '#confirmDelete', function(event) {
    $(this).blur()
    if ($("#basic-information").find('form').attr('action').includes('contract_people')) {
      lockButton('#confirmDelete')

      request.delete($("#offcanvasRight .tab-pane.active form").attr('action'))
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          addToast(res.body.success, 'notice')
          if (contractPeopleDatatable !== null) {
            contractPeopleDatatable.ajax.reload()
          }
          mainModal.hide()
          $('#offcanvasRight .btn-close').click()
        })
        .catch(err => {
          handleErrors(err, '#confirmDelete')
        })
    }
  })
}
