import request from 'superagent'
import { addToast } from 'src/toasts'
import { Tooltip } from 'bootstrap'
import moment from 'moment-timezone' // https://momentjs.com/
import { Calendar } from '@fullcalendar/core' // https://fullcalendar.io/
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import fiLocale from '@fullcalendar/core/locales/fi'

window.eventCalendar = null

export function functions_event() {
  $('#offcanvasRight').on('change', '#event-select', function(event) {
    const id = $('#event-select').val()
    if (id) {
      eventCalendar = null
      $.getScript(/events/ + id + '/edit')
    }
  })

  $('#offcanvasRight').on('change', '#event_user_id', function(event) {
    const id = $('#event_user_id').val()
    if (id) {
      if (eventCalendar == null || eventCalendar == undefined) {
        if (document.getElementById('eventCalendar') != null) {
          initCalendar()
        }
      } else {
        eventCalendar.refetchEvents()
      }
    }
  })

  $('#offcanvasRight').on('change', '#event_start_time_date, #event_start_time_time, #event_event_type, #event_duration', function(event) {
    let d = parseInt($('#event_start_time_date').val().split('.')[0])
    let m = parseInt($('#event_start_time_date').val().split('.')[1])
    let y = parseInt($('#event_start_time_date').val().split('.')[2])
    let h = parseInt($('#event_start_time_time').val().split(':')[0])
    let min = parseInt($('#event_start_time_time').val().split(':')[1])
    if (m < 10) {
      m = '0' + String(m)
    }
    if (d < 10) {
      d = '0' + String(d)
    }
    if (h < 10) {
      h = '0' + String(h)
    }
    if (min < 10) {
      min = '0' + String(min)
    }
    let mom = moment.tz(y + '-' + m + '-' + d + ' ' + h + ':' + min + ':00', 'Europe/Helsinki')
    $('#event_start_time').val(mom)

    if (eventCalendar !== null) {
      createEvent()

      const action = $('.tab-pane.active form').first().attr('action')
      let id = null
      if (action !== '/events') {
        id = action.split('/').pop()
      } else {
        id = $('#event_temp_id').val()
      }

      let ev = eventCalendar.getEventById(id)
      const dateStart = new Date(y, m - 1, d, h, min, 0)
      let duration = parseInt($('#event_duration').val())
      const dateEnd = new Date(y, m - 1, d, h, parseInt(min) + duration, 0)
      ev.setStart(dateStart)
      ev.setEnd(dateEnd)
      ev.setProp('classNames', [$('#event_event_type').val()])
      ev.setExtendedProp('type', $('#event_event_type').val())
      ev.setExtendedProp('notes', $('#event_notes').val())
    }
  })

  $('#offcanvasRight').on('change', '#event_booking_result', function(event) {
    if ($(this).val() == 'UUSI_SOITTO') {
      $('#event_new_call_date').closest('.col-4').removeClass('d-none')
    } else {
      $('#event_new_call_date').closest('.col-4').addClass('d-none')
    }
  })

  $('#offcanvasRight').on('change', '#event_event_type', function(event) {
    if ($(this).val() == 'PERUTTU') {
      $('#event_cancelled_notes').closest('.col-4').removeClass('d-none')
      $('#events .meeting_location').addClass('d-none')
    } else if ($(this).val() == 'TAPAAMINEN') {
      $('#event_cancelled_notes').closest('.col-4').addClass('d-none')
      $('#events .meeting_location').removeClass('d-none')
    } else {
      $('#event_cancelled_notes').closest('.col-4').addClass('d-none')
      $('#events .meeting_location').addClass('d-none')
    }
  })
}

export function initCalendar() {
  let d = parseInt($('#event_start_time_date').val().split('.')[0])
  let m = parseInt($('#event_start_time_date').val().split('.')[1]) - 1
  let y = parseInt($('#event_start_time_date').val().split('.')[2])
  const initialDate = new Date(y, m, d)

  let tooltipInstance = null
  const eventCalendarEl = document.getElementById('eventCalendar')
  eventCalendar = new Calendar(eventCalendarEl, {
    plugins: [ timeGridPlugin, interactionPlugin ],
    aspectRatio: 2,
    editable: false,
    eventMouseEnter: function(mouseEnterInfo) {
      if (mouseEnterInfo.event.extendedProps.notes) {
        tooltipInstance = new Tooltip(mouseEnterInfo.el, {
          title: mouseEnterInfo.event.extendedProps.notes.replace(new RegExp('\n', 'g'), '<br>'),
          placement: 'bottom',
          trigger: 'hover',
          container: 'body',
          html: true
        })

        tooltipInstance.show()
      }
    },
    eventMouseLeave: function(mouseLeaveInfo) {
      if (tooltipInstance) {
        tooltipInstance.dispose()
        tooltipInstance = null
      }
    },
    eventSources: [
      function(info, successCallback, failureCallback) {
        const action = $('.tab-pane.active form').first().attr('action')
        let id = null
        if (action !== undefined && action !== '/events') {
          id = action.split('/').pop()
        } else {
          id = $('#event_temp_id').val()
        }
        //const url = '/events?user_id=' + $('#event_user_id').val() + '&event_id=' + id

        request.get('/events')
          .accept('json')
          .query({
            start: info.startStr,
            end: info.endStr,
            user_id: $('#event_user_id').val(),
            event_id: id
          })
          .then(res => {
            successCallback(res.body)

            // create event if new or changed week
            createEvent()
          })
          .catch(err => {
            handleErrors(err, null)
            failureCallback(err)
          })
      }/*,
      function(info, successCallback, failureCallback) {
        request.get('/ms_events')
          .accept('json')
          .query({
            start: info.startStr,
            end: info.endStr,
            user_id: $('#event_user_id').val()
          })
          .then(res => {
            successCallback(res.body)
          })
          .catch(err => {
            handleErrors(err, null)
            failureCallback(err)
          })
      }*/
    ],
    firstDay: 1,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: ''
    },
    height: 'auto',
    initialDate: initialDate,
    initialView: 'timeGridWeek',
    locale: fiLocale,
    nowIndicator: true,
    /*select: function(selectionInfo) {
      // update form dropdowns
      let d = selectionInfo.start.getDate().toString()
      let m = (parseInt(selectionInfo.start.getMonth()) + 1).toString()
      let y = selectionInfo.start.getFullYear().toString()
      let h = selectionInfo.start.getHours().toString()
      if (parseInt(selectionInfo.start.getHours()) < 10 ) {
        h = '0' + selectionInfo.start.getHours().toString()
      }
      let min = selectionInfo.start.getMinutes().toString()
      if (parseInt(selectionInfo.start.getMinutes()) === 0 ) {
        min = '00'
      }

      if (m.length < 2) {
        m = '0' + String(m)
      }
      if (d.length < 2) {
        d = '0' + String(d)
      }
      if (h.length < 2) {
        h = '0' + String(h)
      }
      if (min.length < 2) {
        min = '0' + String(min)
      }
      let mom = moment(y + '-' + m + '-' + d + ' ' + h + ':' + min + ':00')
      $('#event_start_time').val(mom)
      $('#event_start_time_date').val(d + '.' + m + '.' + y)
      $('#event_start_time_time').val(h + ':' + min)

      // move event in calendar
      createEvent()
      const action = $('.tab-pane.active form').first().attr('action')
      let id = null
      if (action !== '/events') {
        id = action.split('/').pop()
      } else {
        id = $('#event_temp_id').val()
      }
      let event = eventCalendar.getEventById(id)
      event.setStart(selectionInfo.start, { maintainDuration: true })
      event.setProp('classNames', ['current_event'])
      event.setExtendedProp('type', $('#event_event_type').val())
      event.setExtendedProp('notes', $('#event_notes').val())

      eventCalendar.unselect()
    },
    selectable: true,*/
    snapDuration: '00:15:00',
    slotMaxTime: "21:00:00",
    slotMinTime: "08:00:00",
    weekends: false
  })
  eventCalendar.render()
}

function createEvent() {
  const action = $('.tab-pane.active form').first().attr('action')
  let id = null
  if (action !== '/events') {
    id = action.split('/').pop()
  } else {
    id = $('#event_temp_id').val()
  }

  if (id) {
    let event = eventCalendar.getEventById(id)
    if (event === null || event === undefined) {
      let d = parseInt($('#event_start_time_date').val().split('.')[0])
      let m = parseInt($('#event_start_time_date').val().split('.')[1]) - 1
      let y = parseInt($('#event_start_time_date').val().split('.')[2])
      let h = parseInt($('#event_start_time_time').val().split(':')[0])
      let min = parseInt($('#event_start_time_time').val().split(':')[1])
      let duration = parseInt($('#event_duration').val())
      const dateStart = new Date(y, m, d, h, min, 0)
      const dateEnd = new Date(y, m, d, h, min + duration, 0)
      event = {
        id: id,
        title: $('#customer_location_name').val() + ' ' + $('#customer_company_name').val(),
        start: dateStart,
        end: dateEnd,
        classNames: ['current_event'],
        type: $('#event_event_type').val(),
        notes: $('#event_notes').val()
      }
      eventCalendar.addEvent(event)
    }
  }
}